import * as React from 'react';

import { AppLoaderCircle } from '@egr/xbox/egr-gui-elements/AppLoader/AppLoaderCircle';

export const AppLoader: React.FC = () => {
    const [ component, setComponent ] = React.useState(<AppLoaderCircle/>);

    React.useEffect(
        (): void => {
            void (async (): Promise<void> => {
                const { getApp } = await import('./renderApp');

                const RealApp: React.FC = await getApp();

                setComponent(React.createElement(RealApp));
            })();
        },
        [] // call only once
    );
    return component;
};

AppLoader.displayName = 'AppLoader';